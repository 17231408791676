import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "react-bootstrap";

export default function IconBreadcrumbs({ name, path }) {
  return (
    <Box
      style={{
        width: "100%",
        marginBottom: "60px",
        padding: "36px 0",
        borderTop: "1px solid #e1e7ec",
        borderBottom: "1px solid #e1e7ec",
        color: "gray",
      }}
      className={" bg-brand-gray-300"}
    >
      <Container>
        <Box className={"d-flex justify-content-between"}>
          <Box>
            <Typography variant={"h6"} className={"text-uppercase"}>
              {name}
            </Typography>
          </Box>
          <Breadcrumbs
            style={{
              borderBottom: "none",
            }}
            aria-label="breadcrumb"
          >
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to="/"
              style={{
                textDecoration: "none",
                color: "gray",
              }}
            >
              Home
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {name}
            </Typography>
          </Breadcrumbs>
        </Box>
      </Container>
    </Box>
  );
}
