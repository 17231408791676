import React from "react";
import Form from "../common/form";
import { Container } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import AuthService from "../../services/authService";
import * as Yup from "yup";
import { toast } from "react-toastify";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

class ChangePassword extends Form {
  apiEndPoint = config.apiEndPoint + "active-user/changepass";

  schema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Password is a required field")
      .min(4, "Minimum is 4 characters")
      .max(12, "Maximum is 12 digits"),
    newPassword: Yup.string()
      .required("Password is a required field")
      .min(4, "Minimum is 4 characters")
      .max(12, "Maximum is 12 digits"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match",
    ),
  });

  recaptchaRef = React.createRef();

  async doSubmit(values, captchaResponse) {
    return axios
      .post(this.apiEndPoint, { ...values, captchaResponse: captchaResponse })
      .then((result) => result.data)
      .then((result) => {
        const token = result?.token;

        if (!token) {
          toast.error("Something went wrong.");
          return;
        }
        AuthService.storeJWT(token);
        toast.success("Password changed successfully");
        setTimeout(() => {
          window.location = "/";
        }, 1500);
      })
      .catch((ex) => {
        const errors = ex?.response?.data?.errors;
        if (errors) {
          const p = Object.entries(errors);
          p.forEach((e) => {
            const err = e[1][0];
            toast.error(err);
          });
        }
        const msgError = ex?.response?.data?.error;
        if (msgError) {
          toast.error(msgError);
        }
      })
      .then(() => {
        this.recaptchaRef.current.reset();
      });
  }

  render() {
    return (
      <>
        <IconBreadcrumbs name={"Change Password"} />
        <Container className={"pb-5"}>
          <div className={"flex justify-center "}>
            <div className={"max-w-full lg:w-2/3 "}>
              <p className={"text-lg font-bold"}>Change your password</p>
              <div className={"border-1.5 p-12"}>
                <Formik
                  validationSchema={this.schema}
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  onSubmit={(values) => {
                    this.doSubmit(values, this.recaptchaRef.current.getValue());
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Box>
                      <form noValidate onSubmit={handleSubmit}>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Old Password</p>
                          <TextField
                            type="password"
                            name="oldPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                            placeholder="Enter your old password"
                            id="oldPassword"
                            className="form-control"
                          />
                          {errors.oldPassword && touched.oldPassword && (
                            <div className="alert alert-danger mt-3">
                              {errors.oldPassword}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>New Password</p>
                          <TextField
                            type="password"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            placeholder="Enter your new password"
                            id="newPassword"
                            className="form-control"
                          />
                          {errors.newPassword && touched.newPassword && (
                            <div className="alert alert-danger mt-3">
                              {errors.newPassword}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Confirm Password</p>
                          <TextField
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            placeholder="Confirm your password"
                            id="confirmPassword"
                            className="form-control"
                          />
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="alert alert-danger mt-3">
                                {errors.confirmPassword}
                              </div>
                            )}
                        </Box>
                        <Box className="mb-3">
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey={config.RECAPTCHA_SITE_KEY}
                          ></ReCAPTCHA>
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={""}
                        >
                          Change Password
                        </Button>
                      </form>
                    </Box>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ChangePassword;
