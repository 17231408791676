import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";

export const SpecialFeaturesCard = ({ icon, title, value }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid
      lg={4}
      xs={12}
      style={{
        padding: "30px",
      }}
    >
      <Box
        style={{
          position: "relative",
          overflow: "hidden",
          color: "#242930",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.04), 0px 1px 3px 0px rgba(0,0,0,0.03)",
          padding: "30px",
          borderRadius: "15px",
          transition: "transform 1s ease",
          transform: isHovered ? "translateY(-5px)" : "none",
          textAlign: "center",
        }}
        className={`bg-brand-gray-300 `}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <i
          className={`fa fa-4x ${icon} ${isHovered ? "text-brand-blue-100" : ""}`}
          aria-hidden="true"
        ></i>
        <Typography
          mt={2}
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            textTransform: "uppercase",
            transition: "transform 1s ease",
          }}
          className={`${isHovered ? "text-brand-blue-100" : ""}`}
        >
          {title}
        </Typography>
        <Typography className={"mt-1"}>{value}</Typography>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "4px",
            transform: isHovered ? "translateY(0)" : "translateY(100%)",
            transition: "transform 0.3s ease",
          }}
          className={"bg-brand-blue-100"}
        />
      </Box>
    </Grid>
  );
};
