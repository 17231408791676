import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NotFound404Page = () => {
  const navigate = useNavigate();
  return (
    <Container
      style={{
        paddingTop: "55px",
      }}
    >
      <h3>Page not found</h3>
      <Button sx={{ mt: 2 }} variant="outlined" onClick={() => navigate("/")}>
        Go back home
      </Button>
    </Container>
  );
};
