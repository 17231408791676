import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "react-bootstrap";
import config from "../../../config.json";
import axios from "axios";

const columns = [
  { id: "name", label: "Event Name", minWidth: 150 },
  { id: "day", label: "Day", minWidth: 50 },
  { id: "hour", label: "Hour", minWidth: 50 },
  { id: "duration", label: "Duration", minWidth: 50 },
  { id: "reward", label: "Reward", minWidth: 150 },
];

export const EventsTable = () => {
  const apiEndPoint = config.apiEndPoint + "info/events";
  const [events, setEvents] = React.useState([]);
  React.useEffect(() => {
    axios.get(apiEndPoint).then(({ data }) => setEvents(data));
  }, []);
  return (
    <Container>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "60vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell>{row.eventName}</TableCell>
                    <TableCell>{row.day}</TableCell>
                    <TableCell>{row.hour}</TableCell>
                    <TableCell>{row.min}</TableCell>
                    <TableCell>{row.reward}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};
