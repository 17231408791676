import React from "react";
import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import Logo from "../../assets/LogoNew.png";

const Footer = (props) => {
  return (
    <Box
      style={{
        boxShadow: "rgba(0, 0, 0, 0.2) 0px -1px 10px 0px",
      }}
      className={"bg-brand-gray-100 py-5"}
    >
      <Container>
        <div className={"md:flex justify-between text-white"}>
          <div>
            <div className={"flex gap-3 items-center"}>
              <img src={Logo} alt={"Logo"} className={"max-w-12 mb-3"} />
              <p className={"font-semibold text-lg"}>Revelation Conquer</p>
            </div>
            <p className={"max-w-100"}>
            Revelation Conquer is an independent, private server designed to offer a distinctive and immersive gaming experience for Conquer enthusiasts.
            We would like to emphasize that our server is in no way affiliated with, endorsed by, or officially associated with TQ Digital Entertainment, the original creators of Conquer Online.
            Our goal is to build a unique community and gaming environment that celebrates the spirit of the game, while remaining fully independent from the official platform.{" "}
            </p>
            <p className={"font-medium text-lg mt-5"}>Our Links and Partners</p>
            <div className={"grid grid-cols-2 gap-2"}>
              <a href={"https://www.facebook.com/RevelationConquer1.0"}>Facebook</a>
              <a href={"https://www.facebook.com/revelation.conquer/"}>Facebook</a>
              <a href={"https://discord.gg/RBGPqzggFZ"}>Discord</a>
              <a href={"https://www.youtube.com/@revelationconquer7738"}>Youtube</a>
            </div>
          </div>
          <div className={"mt-3 md:mt-0"}>
            <iframe
              src="https://discord.com/widget?id=744577322664984706&theme=dark"
              width="350"
              height="300"
              allowTransparency="true"
              frameBorder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
