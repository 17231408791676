import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import config from "../../config.json";
import axios from "axios";
import { Masthead } from "../masthead";
import { Box, Grid } from "@mui/material";
import { FeaturesTab } from "../common/features-tab/features-tab";
import { GameVideo } from "../common/game-video/game-video";
import { GameCard } from "../common/game-card/game-card";
import moment from "moment";
import { parseSeconds } from "../../utils/custom-utils";
import { SpecialFeaturesCard } from "../common/special-features-card/special-features-card";
import ClassicDescription from "../../assets/classic-description.png";
import MeteorsImg from "../../assets/meteor.png";
import DragonImg from "../../assets/dragonball.png";
import GoldImg from "../../assets/gold.png";
import { GameDropStatisticsCard } from "../common/game-drop-statistics-card/game-drop-statistics-card";

const Home = () => {
  const [newServerInfo, setNewServerInfo] = useState();
  const [serverUpTime, setServerUpTime] = useState();

  useEffect(() => {
    const apiEndPointNew = config.apiEndPoint + "statistics/s/2";
    axios
      .get(apiEndPointNew)
      .then((response) => response.data)
      .then((data) => {
        setNewServerInfo({ ...data, ...data?.result });

        const lastServerUptime = data?.result?.lastStampTimestamp;
        if (lastServerUptime && moment(lastServerUptime).isValid()) {
          const diff = moment().diff(lastServerUptime, "seconds");
          const { days, hours, minutes } = parseSeconds(diff);
          setServerUpTime(`${days} Days ${hours} Hours ${minutes} Minutes`);
        }
        console.log(data);
      });
  }, []);

  useEffect(() => {
    console.log("serverUpTime=", serverUpTime);
  }, [serverUpTime]);
  return (
    <Box>
      <Masthead />
      <Container>
        <Grid mt={5} mb={5} container spacing={3}>
          <Grid item lg={4} xs={12}>
            <FeaturesTab />
          </Grid>
          <Grid item lg={8} xs={12}>
            <GameVideo />
          </Grid>
        </Grid>
      </Container>
      <Box
        style={{
          minHeight: "400px",
        }}
        className={"bg-brand-gray-100"}
      >
        <Container>
          <Box style={{ paddingTop: "50px" }}>
            <p className={"text-4xl font-bold text-white uppercase"}>
              Server related feeds
            </p>
          </Box>
          <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
            <GameCard
              title={"Server Status"}
              value={newServerInfo?.isOnline ? "ONLINE" : "OFFLINE"}
              icon={"fa-desktop"}
            />
            <GameCard
              title={"Online Players"}
              value={newServerInfo?.online ?? 0}
              icon={"fa-users"}
            />
            <GameCard
              title={"Server Uptime"}
              value={
                !newServerInfo?.isOnline || !serverUpTime
                  ? "None"
                  : serverUpTime
              }
              icon={"fa-clock-o"}
            />
            <GameCard
              icon={"fa-globe"}
              title={"Last GW winner"}
              value={newServerInfo?.lastGuildwarWinner ?? "none"}
            />
            <GameCard
              icon={" fa-user-circle"}
              title={"Last Character"}
              value={newServerInfo?.lastCharacter ?? "none"}
            />
            <GameCard icon={" fa-bolt"} title={"Game Version"} value={"5165"} />
          </div>
        </Container>
      </Box>
      <Box
        style={{
          minHeight: "400px",
        }}
        className={"text-brand-gray-100"}
      >
        <Container>
          <div className={"flex justify-center"}>
            <div className={"text-center"}>
              <p className={"text-4xl font-bold uppercase pt-24"}>
                Our Special Features
              </p>
              <p>
              Let's be honest—if you're searching for an easy, casual server, this might not be the place for you.
              </p>
              <p>
                {" "}
                Our server is designed for those seeking to recapture the classic feeling of nostalgia. It's not just a trip down memory lane; it's a challenge meant for dedicated players with real skill.
              </p>
              <p>
              Here, hard work truly pays off, and even a little effort can go a long, long way.
              </p>
            </div>
          </div>

          <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
            <SpecialFeaturesCard
              title={"Closest Experience"}
              value={
                "Best Conquer Online experience as much as you used to experience it."
              }
              icon={"fa-certificate"}
            />
            <SpecialFeaturesCard
              title={"Fairest Ever"}
              value={
                "FREE of battle power, Siding for a particular player and donation items."
              }
              icon={"fa-lightbulb-o"}
            />
            <SpecialFeaturesCard
              title={"Simply & Clean"}
              value={"The pure client 1.0 interface with high graphics engine."}
              icon={"fa-podcast"}
            />
            <SpecialFeaturesCard
              title={"Best Rate"}
              value={
                "Real low rates & experience as it was on Conquer Online 1.0."
              }
              icon={"fa-star"}
            />
            <SpecialFeaturesCard
              title={"Advance Emulator"}
              value={
                "Custom Conquer online emulator to make special classic game-play."
              }
              icon={"fa-cog"}
            />
            <SpecialFeaturesCard
              title={"BattlEye"}
              value={
                "Extremely anti cheat and self report to provide fair game."
              }
              icon={"fa-eye"}
            />
          </div>
        </Container>
      </Box>
      <Box
        style={{
          minHeight: "400px",
        }}
        className={"bg-brand-gray-100"}
      >
        <Container>
          <img src={ClassicDescription} alt={"Classic description"} />
        </Container>
      </Box>
      <Box
        style={{
          minHeight: "400px",
        }}
        className={"text-brand-gray-100"}
      >
        <Container>
          <div className={"flex justify-center"}>
            <div className={"text-center"}>
              <p className={"text-4xl font-bold uppercase pt-24"}>
                Drops Since Restart
              </p>
            </div>
          </div>

          <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
            <GameDropStatisticsCard
              title={"Meteors"}
              value={newServerInfo?.metsSinceRestart?.toLocaleString() ?? 0}
              icon={MeteorsImg}
            />
            <GameDropStatisticsCard
              title={"DragonBalls"}
              value={newServerInfo?.dbsSinceRestart?.toLocaleString() ?? 0}
              icon={DragonImg}
            />
            <GameDropStatisticsCard
              title={"Gold"}
              value={newServerInfo?.goldsSinceRestart?.toLocaleString() ?? 0}
              icon={GoldImg}
            />
          </div>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
