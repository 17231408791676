import { Box } from "@mui/material";
import { EventsTable } from "../common/events-table/events-table";
import IconBreadcrumbs from "../custom-breadcrumb";

const EventsPage = () => {
  return (
    <Box>
      <IconBreadcrumbs name="Events" path={"/events"} />
      <EventsTable />
    </Box>
  );
};
export default EventsPage;
