import { Box, Typography } from "@mui/material";
import IconBreadcrumbs from "../custom-breadcrumb";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "react-bootstrap";
import { Separator } from "../common/separator/separator";
import axios from "axios";
import config from "../../config.json";

export const StatisticsPage = () => {
  const columns = [
    { id: "id", label: "#", minWidth: 50 },
    { id: "value", label: "Stat", minWidth: 150 },
  ];
  const events = [
    { id: "Total Dragon Balls Dropped", value: 101 },
    { id: "Total Meteors Dropped", value: 2484 },
    { id: "Minned Gems", value: 42413 },
    { id: "Gold Dropped", value: 100530500 },
  ];

  const Stats = (serverId) => {
    const [serverInfo, setServerInfo] = React.useState();
    React.useEffect(() => {
      const apiEndPoint = config.apiEndPoint + "statistics/s/" + serverId;
      axios
        .get(apiEndPoint)
        .then((response) => response.data)
        .then((data) => {
          setServerInfo({...data, ...data?.result});
          console.log(data);
        });
    }, []);
    const Row = (id, value) => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={id}>
          <TableCell
            style={{
              fontWeight: 600,
            }}
          >
            {id}
          </TableCell>
          <TableCell>
            {value && !isNaN(value)
              ? value?.toLocaleString()
              : (value ?? "None")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden", mb: 5 }}>
        <TableContainer sx={{ maxHeight: "60vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Row("Total dragon balls dropped", serverInfo?.dbsSinceRestart)}
              {Row("Total meteors dropped", serverInfo?.metsSinceRestart)}
              {Row("Total gold dropped", serverInfo?.goldsSinceRestart)}
              {Row("Total +1 since restart", serverInfo?.plusSinceRestart)}
              {Row("Online", serverInfo?.online)}
              {Row("Last Character Created", serverInfo?.lastCharacter)}
              {Row("Last Guildwar Winner", serverInfo?.lastGuildwarWinner)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };
  return (
    <Box>
      <IconBreadcrumbs name="Statistics" />
      <Container>
        <Typography variant="h5" mb={5}>
          All these statistics are since last restart
        </Typography>
        <Box>
          <Separator title={"Revelation Statistics"} />
          {Stats(2)}
        </Box>
      </Container>
    </Box>
  );
};
