import * as React from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import RankingsPage from "../common/server-ranks/ranks";
import IconBreadcrumbs from "../custom-breadcrumb";

export default function RankingsTab() {
  return (
    <Box>
      <IconBreadcrumbs name="Rankings" />
      <Container>
        <Box sx={{ bgcolor: "background.paper" }}>
          <RankingsPage serverId={2} />
        </Box>
      </Container>
    </Box>
  );
}
