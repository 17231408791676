import { useEffect } from "react";

const useSlider = (slideImage, slideText, slideTitle, images) => {
  let slideCounter = 0;

  useEffect(() => startSlider());

  const startSlider = () => {
    slideImage.current.style.backgroundImage = `url(${images[0].src})`;
    slideText.current.innerHTML = images[0].text;
    slideTitle.current.innerHTML = images[0].title;
  };

  const handleSlide = (slide) => {
    slideImage.current.style.backgroundImage = `url(${images[slide - 1].src})`;
    slideText.current.innerHTML = images[slide - 1].text;
    slideTitle.current.innerHTML = images[slide - 1].title;
    animateSlide(slideImage);
  };

  const animateSlide = () => {
    slideImage.current.classList.add("fadeIn");
    setTimeout(() => {
      slideImage.current.classList.remove("fadeIn");
    }, 700);
  };

  const goToPreviousSlide = () => {
    if (slideCounter === 0) {
      handleSlide(images.length);
      slideCounter = images.length;
    }

    handleSlide(slideCounter);
    slideCounter--;
  };

  const goToNextSlide = () => {
    if (slideCounter === images.length - 1) {
      startSlider();
      slideCounter = -1;
      animateSlide(slideImage);
    }

    slideImage.current.style.backgroundImage = `url(${images[slideCounter + 1].src})`;
    slideTitle.current.innerHTML = images[slideCounter + 1].title;
    slideText.current.innerHTML = images[slideCounter + 1].text;
    slideCounter++;
    animateSlide(slideImage);
  };

  return { goToPreviousSlide, goToNextSlide };
};

export default useSlider;
