import Slider from "./common/slider/slider";

export const Masthead = () => {
  const imgs = [
    {
      title: "Revelation Conquer",
      src: "/img/1.jpg",
      text: "RevelationConquer is a classic server that’s based on version 1.0.",
    },
    {
      title: "Share your in-game event screenshots for a chance to be featured here!",
      src: "/img/3.jpg",
      text: "During our Super GuildWAR its So much Fun! so what are you waiting for? come and Join us!",
    },
    {
      title: "Send in your in-game event screenshots for a chance to see them highlighted here!",
      src: "/img/5.jpg",
      text: "We have shared and distributed 200 pieces of our first Revelation T-shirt to players in celebration of our game server reaching over 1,000 players online.",
    },
    {
      title: "Upload your in-game event screenshot to be posted here!",
      src: "/img/4.jpg",
      text: "We have implemented a gallery in our Discord channel, where players can upload their in-game screenshots. Each week, we'll select and post these screenshots on our website.",
    },
    {
      title: "Upload your in-game event screenshot to be posted here!",
      src: "/img/2.png",
      text: "Our new game logo was created by one of our top players, who generously shared their photo editing skills!",
    },
    {
      title: "Upload your in-game event screenshot to be posted here!",
      src: "/img/6.jpg",
      text: "Our new game launcher has been meticulously developed and generously shared by one of our talented game coders, whose expertise and dedication have significantly contributed to enhancing our gaming experience.",
    },
  ];
  return <Slider images={imgs} />;
};