import React from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import * as Yup from "yup";
import { toast } from "react-toastify";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const username = searchParams.get("username");
  const token = searchParams.get("token");
  const navigate = useNavigate();

  if (!token || !username) {
    navigate("/");
    return;
  }
  const apiEndPoint =
    config.apiEndPoint + `users/recoverpassword/${username}/${token}`;

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is a required field")
      .min(4, "Minimum is 4 characters")
      .max(15, "Maximum is 15 digits"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match",
    ),
  });

  const recaptchaRef = React.createRef();

  const doSubmit = (values, captchaResponse) => {
    return axios
      .post(apiEndPoint, { ...values, captchaResponse: captchaResponse })
      .then((result) => result.data)
      .then((result) => {
        toast.success("Password changed successfully");
        setTimeout(() => {
          window.location = "/";
        }, 1500);
      })
      .catch((ex) => ex?.response?.data)
      .then((ex) => {
        toast.error(ex);
        recaptchaRef.current.reset();
      });
  };

  return (
    <>
      <IconBreadcrumbs name={"Reset Password"} />
      <Formik
        validationSchema={schema}
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        onSubmit={(values) => {
          // Alert the input values of the form that we filled
          doSubmit(values, recaptchaRef.current.getValue());
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Container>
            <Box
              style={{
                border: "1px solid rgb(225, 231, 236)",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box className="header">
                <h3 className="fw-bold p-2 text-uppercase">Change Password</h3>
              </Box>
              <Box className="content">
                <form noValidate onSubmit={handleSubmit}>
                  <Box className="mb-3">
                    <TextField
                      type="password"
                      name="newPassword"
                      label="New Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      placeholder="Enter your new password"
                      id="newPassword"
                      className="form-control"
                    />
                    {errors.newPassword && touched.newPassword && (
                      <div className="alert alert-danger mt-3">
                        {errors.newPassword}
                      </div>
                    )}
                  </Box>
                  <Box className="mb-3">
                    <TextField
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      placeholder="Confirm your password"
                      id="confirmPassword"
                      className="form-control"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="alert alert-danger mt-3">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </Box>
                  <Box className="mb-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={config.RECAPTCHA_SITE_KEY}
                    ></ReCAPTCHA>
                  </Box>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="success"
                    className="form-control"
                  >
                    Change Password
                  </Button>
                </form>
              </Box>
            </Box>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
