import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/LogoNew.png";
import BurgerMenuIcon from "../../assets/burger-icon.svg";
import { slide as BurgerMenu } from "react-burger-menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AuthService from "../../services/authService";

const pages = [
  "Home",
  "Downloads",
  "Ranks",
  "Events",
  "Market",
  "Statistics",
  "Store",
];
const pagesPath = [
  "/",
  "/downloads",
  "/ranks",
  "/events",
  "/market",
  "/statistics",
  "https://discord.gg/eMnHh2V",
];
const settingsLoggedIn = ["Change Password", "Logout"];
const loggedInPages = ["/user/change-password", "/user/logout"];

const settingsLoggedOut = ["Login", "Forgot password"];
const loggedOutPages = ["/user/login", "/user/forgot-password"];

export const DesktopNavigationBar = () => {
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isAccountOpen = Boolean(anchorEl);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleAccountClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsBurgerMenuOpen(false);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorEl(null);
    setIsBurgerMenuOpen(false);

    if (page) {
      doNav(page);
      window.scrollTo(0, 0);
    }
  };

  const handleNavAnchor = (page) => {
    setAnchorEl(null);
    setIsBurgerMenuOpen(false);

    if (page) {
      window.scrollTo(0, 0);
      history(page);
    }
  };

  const doNav = (page) => {
    if (page) {
      const index = pages.indexOf(page);
      if (index !== -1) {
        const link = pagesPath[index];
        if (link.includes("https://")) {
          window.location.href = link;
        } else {
          history(link);
        }
      }
      const index2 = settingsLoggedIn.indexOf(page);
      if (index2 !== -1) {
        history(loggedInPages[index2]);
      }
      const index3 = settingsLoggedOut.indexOf(page);
      if (index3 !== -1) {
        history(loggedOutPages[index3]);
      }
    }
  };

  return (
    <>
      <div className={"display-block md:hidden"}>
        <BurgerMenu
          isOpen={isBurgerMenuOpen}
          onStateChange={({ isOpen }) => {
            setIsBurgerMenuOpen(isOpen);
          }}
        >
          {pages.map((page) => (
            <div
              key={page}
              onClick={() => handleCloseNavMenu(page)}
              className={
                "text-white hover:text-brand-blue-100 cursor-pointer font-medium border-b-2 border-b-brand-gray-200 p-3"
              }
            >
              {page}
            </div>
          ))}
          {AuthService.isUserLoggedIn() && (
            <>
              <div
                key={"Change Password"}
                onClick={() => handleNavAnchor("/user/change-password")}
                className={
                  "text-white hover:text-brand-blue-100 cursor-pointer font-medium border-b-2 border-b-brand-gray-200 p-3"
                }
              >
                Change Password
              </div>
              <div
                key={"Logout"}
                onClick={() => handleNavAnchor("/user/logout")}
                className={
                  "text-white hover:text-brand-blue-100 cursor-pointer font-medium border-b-2 border-b-brand-gray-200 p-3"
                }
              >
                Logout
              </div>
            </>
          )}
          {!AuthService.isUserLoggedIn() && (
            <>
              <div
                key={"Change Password"}
                onClick={() => handleNavAnchor("/user/forgot-password")}
                className={
                  "text-white hover:text-brand-blue-100 cursor-pointer font-medium border-b-2 border-b-brand-gray-200 p-3"
                }
              >
                Forgot Password
              </div>
              <div
                key={"Login"}
                onClick={() => handleNavAnchor("/user/login")}
                className={
                  "text-white hover:text-brand-blue-100 cursor-pointer font-medium border-b-2 border-b-brand-gray-200 p-3"
                }
              >
                Login
              </div>
            </>
          )}
        </BurgerMenu>
      </div>

      <AppBar position={isBurgerMenuOpen ? "" : "fixed"}>
        <div className={"bg-white text-black px-12 flex min-h-24 items-center"}>
          <div className={"display-block md:hidden"}>
            <img
              onClick={() => setIsBurgerMenuOpen(true)}
              src={BurgerMenuIcon}
              className={"mr-6"}
              alt={"burger icon"}
            />
          </div>
          <img src={Logo} alt={"logo"} className={"max-h-16"} />

          <div
            className={
              "hidden md:flex justify-center w-full gap-x-4 items-center "
            }
          >
            {pages.map((page) => (
              <div
                key={page}
                onClick={() => handleCloseNavMenu(page)}
                sx={{
                  my: 2,
                  display: "block",
                  fontWeight: "bold",
                }}
                style={{
                  fontFamily: "arial",
                  textTransform: "uppercase",
                }}
                className={
                  "text-brand-gray-200 hover:text-brand-blue-100 cursor-pointer font-semibold text-base hover:border-t-2 border-t-brand-blue-100 transition ease-in-out delay-150"
                }
              >
                {page}
              </div>
            ))}
            <div>
              <div
                ref={anchorEl}
                key={"Account"}
                onClick={handleAccountClick}
                sx={{
                  my: 2,
                  display: "block",
                  fontWeight: "bold",
                }}
                style={{
                  fontFamily: "arial",
                  textTransform: "uppercase",
                }}
                className={
                  "text-brand-gray-200 hover:text-brand-blue-100 cursor-pointer font-semibold text-base hover:border-t-2 border-t-brand-blue-100 transition ease-in-out delay-150"
                }
              >
                Account
              </div>
              <Menu
                open={isAccountOpen}
                anchorEl={anchorEl}
                onClose={handleNavAnchor}
                className={"mt-3"}
              >
                <MenuItem onClick={() => handleNavAnchor("/user/register")}>
                  Register
                </MenuItem>
                {AuthService.isUserLoggedIn() && (
                  <>
                    <MenuItem
                      onClick={() => handleNavAnchor("/user/change-password")}
                    >
                      Change Password
                    </MenuItem>
                    <MenuItem onClick={() => handleNavAnchor("/user/logout")}>
                      Logout
                    </MenuItem>
                  </>
                )}
                {!AuthService.isUserLoggedIn() && (
                  <>
                    <MenuItem
                      onClick={() => handleNavAnchor("/user/forgot-password")}
                    >
                      Forgot password
                    </MenuItem>
                    <MenuItem onClick={() => handleNavAnchor("/user/login")}>
                      Login
                    </MenuItem>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </AppBar>
    </>
  );
};
