import { Box, Typography } from "@mui/material";

export const Separator = ({ title }) => {
  return (
    <Box
      style={{
        overflow: "hidden",
        marginBottom: "10px",
        textAlign: "center",
      }}
    >
      {title && (
        <Typography
          variant="h5"
          style={{
            marginBottom: "10px",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: "transparent",
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: "#2d3239",
          display: "inline-block",

          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "50%",
            width: "70px",
            height: "2px",
            marginTop: "-1px",
            left: "-80px",
            backgroundColor: "gray",
          }}
        ></span>
        <span
          style={{
            position: "absolute",
            top: "50%",
            width: "70px",
            height: "2px",
            marginTop: "-1px",
            right: "-80px",
            backgroundColor: "gray",
          }}
        ></span>
      </Box>
    </Box>
  );
};
