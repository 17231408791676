import "./App.css";
import "./fonts.css";
import AuthService from "./services/authService";
import { Navigate, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import LoginPage from "./components/pages/login-page";
import Logout from "./components/pages/logout";
import RegisterPage from "./components/pages/register-page";
import ForgotPassword from "./components/pages/forgot-password";
import ChangePassForm from "./components/pages/change-pass";
import UserProfile from "./components/pages/profile";
import Home from "./components/pages/home";
import Downloads from "./components/pages/downloads";
import Footer from "./components/common/footer";
import React from "react";
import Box from "@mui/material/Box";
import { FeaturesPage } from "./components/pages/features";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useInterceptor } from "./services/axios-interceptors";
import { MarketPlacePage } from "./components/pages/marketplace";
import { PlayerProfile } from "./components/pages/player-profile";
import { FinderPage } from "./components/pages/finder-page";
import ResetPassword from "./components/pages/reset-password";
import EventsPage from "./components/pages/events-page";
import RankingsTab from "./components/pages/server-ranks-tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NotFound404Page } from "./components/pages/404";
import { StatisticsPage } from "./components/pages/statistics";
import { DesktopNavigationBar } from "./components/NavBar/DesktopNavigationBar";

const App = () => {
  useInterceptor();
  const ProtectedRouteLoggedIn = ({ children }) => {
    if (!AuthService.isUserLoggedIn()) {
      return <Navigate to="/user/login" replace />;
    }
    return children;
  };
  const ProtectedRouteNotLogged = ({ children }) => {
    if (AuthService.isUserLoggedIn()) {
      return <Navigate to="/" replace />;
    }
    return children;
  };
  const THEME = createTheme({
    typography: {
      fontFamily: `"Reaver", serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });
  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
        <ToastContainer />
        <DesktopNavigationBar />
        <Box
          className="main"
          style={{
            paddingTop: "96px",
          }}
        >
          <Routes>
            <Route path="/ranks" element={<RankingsTab />}></Route>
            <Route path="/downloads" element={<Downloads />}></Route>
            <Route
              path="/user/login"
              element={
                <ProtectedRouteNotLogged>
                  <LoginPage />
                </ProtectedRouteNotLogged>
              }
            ></Route>
            <Route path="/user/logout" element={<Logout />}></Route>
            <Route path="/user/profile" element={<UserProfile />}></Route>
            <Route path="/events" element={<EventsPage />}></Route>
            <Route
              path="/user/change-password"
              element={
                <ProtectedRouteLoggedIn>
                  <ChangePassForm />
                </ProtectedRouteLoggedIn>
              }
            ></Route>
            <Route path="/recoverpassword" element={<ResetPassword />}></Route>
            <Route path="/user/register" element={<RegisterPage />}></Route>
            <Route path="/features" element={<FeaturesPage />}></Route>
            <Route path="/finder" element={<FinderPage />}></Route>
            <Route path="/market" element={<MarketPlacePage />}></Route>
            <Route path="/statistics" element={<StatisticsPage />}></Route>
            <Route
              path="/user/forgot-password"
              element={<ForgotPassword />}
            ></Route>
            <Route
              path="/player-profile/:name"
              element={<PlayerProfile />}
            ></Route>
            <Route path="/404" exact element={<NotFound404Page />}></Route>
            <Route path="/" exact element={<Home />}></Route>

            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Box>
        <Footer />
      </ThemeProvider>
    </div>
  );
};
export default App;
