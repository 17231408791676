import React from "react";
import Form from "../common/form";
import { Container } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import AuthService from "../../services/authService";
import IconBreadcrumbs from "../custom-breadcrumb";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { Button, TextField } from "@mui/material";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

class LoginPage extends Form {
  apiEndPoint = config.apiEndPoint + "users/login";
  recaptchaRef = React.createRef();
  schema = Yup.object().shape({
    username: Yup.string()
      .required("User is a required field")
      .min(4, "Minimum is 4 characters")
      .max(12, "Maximum is 12 digits"),
    password: Yup.string()
      .required("Password is a required field")
      .min(4, "Minimum is 4 characters")
      .max(12, "Maximum is 12 digits"),
  });

  async doSubmit(values, captchaResponse) {
    return axios
      .post(this.apiEndPoint, { ...values, captchaResponse: captchaResponse })
      .then((result) => result.data)
      .then((result) => {
        const token = result?.jwt;

        if (!token) {
          toast.error("Something went wrong.");
          return;
        }
        AuthService.storeJWT(token);
        toast.success("Login successful.. Redirecting");
        setTimeout(() => {
          window.location = "/";
        }, 1500);
      })
      .catch((ex) => {
        const errors = ex?.response?.data?.errors;
        if (errors) {
          const p = Object.entries(errors);
          p.forEach((e) => {
            const err = e[1][0];
            toast.error(err);
          });
        }
        const msgError = ex?.response?.data?.error;
        if (msgError) {
          toast.error(msgError);
        }
      })
      .then(() => {
        this.recaptchaRef.current.reset();
      });
  }

  render() {
    return (
      <>
        <IconBreadcrumbs name={"Login"} />
        <Container>
          <div className={"flex justify-center "}>
            <div className={"max-w-full lg:w-2/3 "}>
              <p className={"text-lg font-bold"}>Login to your Account</p>
              <div className={"border-1.5 p-12"}>
                <Formik
                  validationSchema={this.schema}
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  onSubmit={(values) => {
                    // Alert the input values of the form that we filled
                    this.doSubmit(values, this.recaptchaRef.current.getValue());
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Box>
                      <form noValidate onSubmit={handleSubmit}>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Username</p>
                          <TextField
                            autoComplete="current-password"
                            type="username"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            placeholder="Enter username here"
                            id="username"
                            className="form-control"
                          />
                          {errors.username && touched.username && (
                            <div className="alert alert-danger mt-3">
                              {errors.username}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Password</p>
                          <TextField
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Enter password"
                            id="password"
                            className="form-control"
                          />
                          {errors.password && touched.password && (
                            <div className="alert alert-danger mt-3">
                              {errors.password}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey={config.RECAPTCHA_SITE_KEY}
                          ></ReCAPTCHA>
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={""}
                        >
                          Login
                        </Button>
                      </form>
                    </Box>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default LoginPage;
