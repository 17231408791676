import Box from "@mui/material/Box";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Container } from "react-bootstrap";
import { Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const FinderPage = () => {
  const playerName = useRef();
  const navigate = useNavigate();
  return (
    <Box>
      <IconBreadcrumbs name={"Find a player"} />
      <Container>
        <Box className={"mb-3"}>
          <Typography>
            Check the information of any player in the server
          </Typography>
          <TextField
            inputRef={playerName}
            id="outlined-basic"
            className={"form-control mt-3"}
            label="Player name"
            variant="outlined"
          />
        </Box>
        <Button
          color={"success"}
          variant={"outlined"}
          onClick={() =>
            navigate(`/player-profile?playerName=${playerName.current.value}`)
          }
          className={"form-control"}
        >
          Search Now
        </Button>
      </Container>
    </Box>
  );
};
