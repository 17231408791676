import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Container } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import Box from "@mui/material/Box";
import FadeIn from "react-fade-in";
import axios from "axios";
import config from "../../config.json";
import { Grid } from "@mui/material";
import { fetchItemImage, findClassName } from "../../utils/custom-utils";
import inventoryImage from "../../assets/inventory.png";

export const PlayerProfile = () => {
  const apiEndPoint = config.apiEndPoint + "info/player-info";
  const [searchParams, setSearchParams] = useSearchParams();
  const playerName = searchParams.get("playerName");
  const [player, setPlayer] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    console.log("PlayerName=", playerName);
    if (!playerName) {
      setIsLoading(false);
      return;
    }
    axios
      .get(`${apiEndPoint}?playerName=${playerName}`)
      .then((req) => req.data)
      .then((response) => {
        console.log(response);
        setPlayer(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const renderPlayerInformation = () => {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item md={1}>
            <Box>
              <img src={`/avatars/${player?.playerInfo?.face}.jpg`} />
            </Box>
          </Grid>
          <Grid item md={2}>
            <h6 className={"mb-3"}>
              <b>Name: </b>
              {player?.playerInfo?.name}
            </h6>
            <h6 className={"mb-3"}>
              <b>Gender: </b>
              {player?.playerInfo?.body / 1000 === 1 ? "Male" : "Female"}
            </h6>
            <h6 className={"mb-3"}>
              <b>Spouse: </b>
              {player?.playerInfo?.spouse}
            </h6>
            <h6 className={"mb-3"}>
              <b>Level: </b>
              {player?.playerInfo?.level}
            </h6>
          </Grid>
          <Grid item md={2}>
            <h6 className={"mb-3"}>
              <b>Class: </b>
              {findClassName(player?.playerInfo?.job)}
            </h6>
            <h6 className={"mb-3"}>
              <b>Silvers: </b>
              {player?.playerInfo?.silvers.toLocaleString()}
            </h6>
            <h6 className={"mb-3"}>
              <b>Nobility: </b>
              {player?.playerInfo?.nobility.toLocaleString()}
            </h6>
          </Grid>
          <Grid item md={7}>
            <Box
              style={{
                background: `url('${inventoryImage}')`,
                width: 256,
                height: 512,
                position: "relative",
              }}
            >
              {player?.gear?.headgear !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 38,
                    height: 38,
                    top: "14%",
                    left: "73%",
                  }}
                  src={fetchItemImage(player?.gear?.headgear)}
                />
              )}
              {player?.gear?.necklace !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 38,
                    height: 38,
                    top: "24%",
                    left: "73%",
                  }}
                  src={fetchItemImage(player?.gear?.necklace)}
                />
              )}
              {player?.gear?.ring !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 38,
                    height: 38,
                    top: "34%",
                    left: "73%",
                  }}
                  src={fetchItemImage(player?.gear?.ring)}
                />
              )}
              {player?.gear?.rightWeapon !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 60,
                    height: 60,
                    top: "45%",
                    left: "65%",
                  }}
                  src={fetchItemImage(player?.gear?.rightWeapon)}
                />
              )}
              {player?.gear?.armor !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 60,
                    height: 60,
                    top: "60%",
                    left: "65%",
                  }}
                  src={fetchItemImage(player?.gear?.armor)}
                />
              )}
              {player?.gear?.leftWeapon !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 60,
                    height: 60,
                    top: "60%",
                    left: "37%",
                  }}
                  src={fetchItemImage(player?.gear?.leftWeapon)}
                />
              )}
              {player?.gear?.boot !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 42,
                    height: 42,
                    top: "64%",
                    left: "16%",
                  }}
                  src={fetchItemImage(player?.gear?.boot)}
                />
              )}
              {player?.gear?.gourd !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 42,
                    height: 42,
                    top: "54%",
                    left: "15%",
                  }}
                  src={fetchItemImage(player?.gear?.gourd)}
                />
              )}
              {player?.gear?.garment !== 0 && (
                <img
                  style={{
                    position: "absolute",
                    width: 42,
                    height: 42,
                    top: "43%",
                    left: "16%",
                  }}
                  src={fetchItemImage(player?.gear?.garment)}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };
  const renderInfo = () => {
    return (
      <FadeIn>
        {!player && <h4>No information for this player</h4>}
        {player && renderPlayerInformation()}
      </FadeIn>
    );
  };
  return (
    <>
      <IconBreadcrumbs name={"Profile"} />
      <Container>
        {isLoading && (
          <Box>
            <Box className={"d-flex justify-content-center"}>
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="ff5e00"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </Box>
            <h6 className="text-center">Doing magic please wait..</h6>
          </Box>
        )}
        {!isLoading && renderInfo()}
      </Container>
    </>
  );
};
