import Box from "@mui/material/Box";
import { Col, Row } from "react-bootstrap";
import IconBreadcrumbs from "../custom-breadcrumb";

export const FeaturesPage = () => {
  return (
    <Box className={"mb-5"}>
      <IconBreadcrumbs name={"Features"} />
      <Box className="container page-content">
        <Row className="mb-5">
          <Col md="6">
            <h2 className="text-center">Core Features</h2>
            <Box>
              <ul>
                <li>All Character Classes, and Spells implemented</li>
                <li>First and Second Rebirth, Reincarnation</li>
                <li>
                  All Items implemented
                  <ul>
                    <li>Dragon Souls</li>
                    <li>Refineries</li>
                    <li>Skill Souls</li>
                    <li>Accessories</li>
                    <li>Steeds &amp; Mount Armors</li>
                    <li>All Epic Classes, Skills and Weapons</li>
                  </ul>
                </li>
                <li>
                  All Maps, Monsters, and Bosses (new Maps, Frozen Grotto, Sea
                  of Death, Dragon Island, Deityland, Lvl6 House, etc)
                </li>
                <li>
                  Cross-Server Capture The Flag and Cross-Server traveling
                </li>
                <li>
                  Achievements, Mentor System, Offline Training, Flower Ranking,
                  Nobility
                </li>
                <li>
                  All Clan / Guild Features (including Arsenal, CTF and Clan PK)
                </li>
                <li>Subclasses, Chi, Jiang-Hu, Prestige</li>
                <li>Tailed Beast, Melting, Anima and Runes</li>
                <li>Lottery, Mail System</li>
                <li>Poker, Roulette, Omaha and VIP Halls</li>
                <li>New Thunderstriker Class</li>
                <li>Inspire Trojan &amp; Ninja (coming soon!)</li>
                <li>
                  All monsters have a chance to drop 5 or 25 CP bags and EXP
                  Balls
                  <ul>
                    <li>
                      <strong>
                        Promotes a fair and fun environment for everyone
                      </strong>
                    </li>
                  </ul>
                </li>

                <li>
                  VIP 4+ players automatically pick up and open the CP bags
                </li>
              </ul>
            </Box>
          </Col>
          <Col md="6">
            <a href="https://content.playconquer.com/site/core-features.webp">
              <img
                src="https://content.playconquer.com/site/core-features_t.webp"
                className="img-fluid m-2 s-img"
                alt="Core Features Image"
              />
            </a>
            <a href="https://content.playconquer.com/site/players.webp">
              <img
                src="https://content.playconquer.com/site/players_t.webp"
                className="img-fluid m-2 s-img"
                alt="Players Image"
              />
            </a>
          </Col>
        </Row>
        <Row className="mb-5">
          <Box className="col-md-6">
            <a href="https://content.playconquer.com/site/pk-arena.webp">
              <img
                src="https://content.playconquer.com/site/pk-arena_t.webp"
                className="img-fluid m-2 s-img"
                alt="PK Arena Image"
              />
            </a>
          </Box>
          <Box className="col-md-6">
            <h2 className="text-center">PvP Events &amp; Tournaments</h2>
            <div>
              <ul>
                <li>
                  PK Tournaments
                  <ul>
                    <li>Team PK</li>
                    <li>Skill PK</li>
                    <li>Skill Team PK</li>
                    <li>Elite PK</li>
                    <li>Weekly PK</li>
                    <li>Extreme PK</li>
                    <li>Class PK</li>
                  </ul>
                </li>
                <li>Arena</li>
                <li>Capture The Flag</li>
                <li>Cross Server Capture the Flag</li>
                <li>Clan War</li>
                <li>Guild War</li>
                <li>Champions Arena</li>
              </ul>
            </div>
          </Box>
        </Row>
        <Row>
          <Col md={6}>
            <h2 className="text-center">Events &amp; Quests</h2>
            <div>
              <ul>
                <li>All Daily Quests</li>
                <li>Horse Racing</li>
                <li>Battlefield Heroes</li>
                <li>Dis City</li>
                <li>CP Castle</li>
                <li>Dragon Island</li>
                <li>Deityland</li>
                <li>Banshee, Nemesis, Terato Dragon, etc</li>
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <a href="https://content.playconquer.com/site/quests.webp">
              <img
                src="https://content.playconquer.com/site/quests_t.webp"
                className="img-fluid m-2 s-img"
                alt="Quests Image"
              />
            </a>
          </Col>
        </Row>
      </Box>
    </Box>
  );
};
