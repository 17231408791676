import React from "react";
import Form from "../common/form";
import { Box, Button, Container, TextField } from "@mui/material";
import axios from "axios";
import config from "../../config.json";
import * as Yup from "yup";
import { toast } from "react-toastify";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

class RegisterPage extends Form {
  apiEndPoint = config.apiEndPoint + "users/register";

  schema = Yup.object().shape({
    username: Yup.string()
      .required("User is a required field")
      .min(4, "Minimum is 4 characters")
      .max(12, "Maximum is 12 digits"),
    password: Yup.string()
      .required("Password is a required field")
      .min(4, "Minimum is 4 characters")
      .max(12, "Maximum is 12 digits"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match",
    ),
    email: Yup.string()
      .required("Email is a required field")
      .email("Please enter a valid Email"),
  });

  recaptchaRef = React.createRef();

  async doSubmit(values, captchaResponse) {
    return axios
      .post(this.apiEndPoint, { ...values, captchaResponse: captchaResponse })
      .then((result) => result.data)
      .then((result) => {
        toast.success("Successfully Registered..");
        setTimeout(() => {
          window.location = "/downloads";
        }, 5000);
      })
      .catch((ex) => {
        const errors = ex?.response?.data?.errors;
        if (errors) {
          const p = Object.entries(errors);
          p.forEach((e) => {
            const err = e[1][0];
            toast.error(err);
          });
        }
        const msgError = ex?.response?.data?.error;
        if (msgError) {
          toast.error(msgError);
        }
      })
      .then(() => {
        this.recaptchaRef.current.reset();
      });
  }

  render() {
    return (
      <>
        <IconBreadcrumbs name={"Register"} />
        <Container className={"pb-5"}>
          <div className={"flex justify-center "}>
            <div className={"max-w-full lg:w-2/3 "}>
              <p className={"text-lg font-bold"}>Register a new account</p>
              <div className={"border-1.5 p-12"}>
                <Formik
                  validationSchema={this.schema}
                  initialValues={{
                    username: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                  }}
                  onSubmit={(values) => {
                    // Alert the input values of the form that we filled
                    this.doSubmit(values, this.recaptchaRef.current.getValue());
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Box>
                      <form noValidate onSubmit={handleSubmit}>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Username</p>
                          <TextField
                            autoComplete="current-password"
                            type="username"
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            placeholder="Enter username here"
                            id="username"
                            className="form-control"
                          />
                          {errors.username && touched.username && (
                            <div className="alert alert-danger mt-3">
                              {errors.username}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Password</p>
                          <TextField
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Enter password"
                            id="password"
                            className="form-control"
                          />
                          {errors.password && touched.password && (
                            <div className="alert alert-danger mt-3">
                              {errors.password}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Confirm Password</p>
                          <TextField
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            placeholder="Confirm your password"
                            id="confirmPassword"
                            className="form-control"
                          />
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="alert alert-danger mt-3">
                                {errors.confirmPassword}
                              </div>
                            )}
                        </Box>
                        <Box className="mb-3">
                          <p className={"font-semibold"}>Email</p>
                          <TextField
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Email"
                            id="email"
                            className="form-control"
                          />
                          {errors.email && touched.email && (
                            <div className="alert alert-danger mt-3">
                              {errors.email}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-3">
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey={config.RECAPTCHA_SITE_KEY}
                          ></ReCAPTCHA>
                        </Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={""}
                        >
                          Register
                        </Button>
                      </form>
                    </Box>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default RegisterPage;
