import { Box, Grid, Typography } from "@mui/material";

export const GameCard = ({ icon, title, value }) => {
  return (
    <Grid
      lg={4}
      xs={12}
      style={{
        padding: "30px",
      }}
    >
      <Box
        style={{
          backgroundColor: "#fff",
          color: "#242930",
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.04), 0px 1px 3px 0px rgba(0,0,0,0.03)",
          padding: "30px",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <i class={`fa fa-4x ${icon}`} aria-hidden="true"></i>
        <Typography
          mt={2}
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography
          mt={1}
          style={{
            fontWeight: "bold",
            fontSize: "1rem",
            textTransform: "uppercase",
            color:
              value === "ONLINE"
                ? "green"
                : value === "OFFLINE"
                  ? "red"
                  : "inherit",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Grid>
  );
};
